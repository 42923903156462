<template>
  <div class="charging-map__panel__main__partners">
    <div class="title">Partneři:</div>
    <div class="logos">
      <a
        href="https://www.audi.cz/"
        target="_blank"
        rel="noopener"
        class="partner-logo"
      >
        <img
          src="https://cdn.24net.cz/resources/5/img/audi-logo.svg"
          width="86"
          height="30"
          alt="Audi logo"
        />
      </a>
      <a href="https://www.energycars.cz/" target="_blank" rel="noopener">
        <img src="https://cdn.24net.cz/resources/5/img/energy-cars-logo.svg" width="83" height="28" alt="EnergyCars logo">
      </a>
      <a
        href="https://www.pre.cz/cs/domacnosti/"
        target="_blank"
        rel="noopener"
      >
        <img
          src="https://cdn.24net.cz/resources/5/img/pre-logo.svg"
          width="51"
          height="28"
          alt="PRE logo"
        />
      </a>
      <a
          href="https://mybox.eco/"
          target="_blank"
          rel="noopener"
      >
        <img
            src="https://cdn.24net.cz/resources/5/img/my-box-logo.svg"
            width="71"
            height="28"
            alt="MyBox logo"
        />
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
.charging-map__panel__main__partners .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  a {
    margin-bottom: 17px;
  }
}
</style>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Partners extends Vue {}
</script>
