import { RouterOptions, RouterMode, RouteConfig } from "vue-router"
import { Component } from "vue-router/types/router";
export class RouterBase implements RouterOptions {
    mode = 'history' as RouterMode;
    routes: RouteConfig[];

    constructor(homeComponent: Component, detailComponent: Component) {
        this.routes = [
            {
                path: '',
                name: 'home',
                component: homeComponent,
            },
            {
                path: '/t/:type',
                name: 'homeFilterType',
                component: homeComponent,
            },
            {
                path: '/p/:provider',
                name: 'homeFilterProvider',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type',
                name: 'homeFilterCharger',
                component: homeComponent,
            },
            {
                path: '/w/:min_output',
                name: 'homeFilterOutput',
                component: homeComponent,
            },
            {
                path: '/m/:payment_method',
                name: 'homeFilterPayment',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type',
                name: 'homeFilterTypeCharger',
                component: homeComponent,
            },
            {
                path: '/t/:type/w/:min_output',
                name: 'homeFilterTypeOutput',
                component: homeComponent,
            },
            {
                path: '/t/:type/m/:payment_method',
                name: 'homeFilterTypePayment',
                component: homeComponent,
            },
            {
                path: '/t/:type/p/:provider',
                name: 'homeFilterTypeProvider',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/w/:min_output',
                name: 'homeFilterChargerOutput',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/m/:payment_method',
                name: 'homeFilterChargerPayment',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/p/:provider',
                name: 'homeFilterChargerProvider',
                component: homeComponent,
            },
            {
                path: '/w/:min_output/m/:payment_method',
                name: 'homeFilterOutputPayment',
                component: homeComponent,
            },
            {
                path: '/w/:min_output/p/:provider',
                name: 'homeFilterOutputProvider',
                component: homeComponent,
            },
            {
                path: '/m/:payment_method/p/:provider',
                name: 'homeFilterPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/w/:min_output',
                name: 'homeFilterTypeChargerOutput',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/m/:payment_method',
                name: 'homeFilterTypeChargerPayment',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/p/:provider',
                name: 'homeFilterTypeChargerProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/w/:min_output/m/:payment_method',
                name: 'homeFilterTypeOutputPayment',
                component: homeComponent,
            },
            {
                path: '/t/:type/w/:min_output/p/:provider',
                name: 'homeFilterTypeOutputProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/m/:payment_method/p/:provider',
                name: 'homeFilterTypePaymentProvider',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/w/:min_output/m/:payment_method',
                name: 'homeFilterChargerOutputPayment',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/w/:min_output/p/:provider',
                name: 'homeFilterChargerOutputProvider',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/m/:payment_method/p/:provider',
                name: 'homeFilterChargerPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/w/:min_output/m/:payment_method/p/:provider',
                name: 'homeFilterOutputPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/m/:payment_method/p/:provider',
                name: 'homeFilterTypeChargerPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/w/:min_output/m/:payment_method',
                name: 'homeFilterTypeChargerOutputPayment',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/w/:min_output/p/:provider',
                name: 'homeFilterTypeChargerOutputProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/w/:min_output/m/:payment_method/p/:provider',
                name: 'homeFilterTypeOutputPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/c/:charger_type/w/:min_output/m/:payment_method/p/:provider',
                name: 'homeFilterChargerOutputPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/t/:type/c/:charger_type/w/:min_output/m/:payment_method/p/:provider',
                name: 'homeFilterTypeChargerOutputPaymentProvider',
                component: homeComponent,
            },
            {
                path: '/*-:id',
                name: 'stationDetail',
                component: detailComponent,
            },
        ];
    }
}