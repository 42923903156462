export class Station implements StationModel {
    id: number;
    name: string;
    url: string;
    status: StationStatus;
    fast_charging?: boolean;
    opening_hours_string?: string;
    charging_slots?: number;
    fast_charging_slots?: number;
    parking_slots?: number;
    station_service?: string;
    refreshment_venues?: string;
    is_non_stop?: boolean;
    note?: string;
    date_changed: string;
    location: LocationModel;
    providers: Array<number>;
    chargers?: Array<StationChargerModel>;
    cables?: Array<CableModel>;
    payment_methods?: Array<number>;
    pics?: Array<PictureModel>;
    opening_hours?: Array<OpeningHoursModel>;
    manufacturer?: number;

    constructor(id: number, { name, url, status, fast_charging, opening_hours_string, charging_slots, fast_charging_slots, parking_slots, station_service, refreshment_venues, is_non_stop, note, date_changed, location, providers, chargers, cables, payment_methods, pics, opening_hours, manufacturer }: StationModel) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.status = status;
        this.date_changed = date_changed;
        this.location = location;
        this.providers = providers;
        this.fast_charging = fast_charging;
        this.opening_hours_string = opening_hours_string;
        this.charging_slots = charging_slots;
        this.fast_charging_slots = fast_charging_slots;
        this.parking_slots = parking_slots;
        this.station_service = station_service;
        this.refreshment_venues = refreshment_venues;
        this.is_non_stop = is_non_stop;
        this.note = note;
        this.chargers = chargers;
        this.cables = cables;
        this.payment_methods = payment_methods;
        this.pics = pics;
        this.opening_hours = opening_hours;
        this.manufacturer = manufacturer;
    }
}

export interface StationModel {
    id?: number;
    name: string;
    url: string;
    status: StationStatus;
    fast_charging?: boolean;
    opening_hours_string?: string;
    charging_slots?: number;
    fast_charging_slots?: number;
    parking_slots?: number;
    station_service?: string;
    refreshment_venues?: string;
    is_non_stop?: boolean;
    note?: string;
    date_changed: string;
    location: LocationModel;
    providers: Array<number>;
    chargers?: Array<StationChargerModel>;
    cables?: Array<CableModel>;
    payment_methods?: Array<number>;
    pics?: Array<PictureModel>;
    opening_hours?: Array<OpeningHoursModel>;
    manufacturer?: number;
}

export interface LocationModel {
    lat: string;
    long: string;
    address?: string;
    country?: string;
}

export interface ChargerModel {
    current_type: string;
    is_fast: boolean;
    name: string;
    order: number;
    show: boolean;
}

export interface StationChargerModel {
    id: string;
    count: string;
    is_fast: string;
    max_current: string;
    max_output: string;
}

export interface CableModel {
    count: string;
    name: string;
}

export interface PaymentMethodModel {
    is_free: boolean;
    name: string;
    order: number;
}

export interface PictureModel {
    name: string;
    description?: string;
    media_url: string;
    width: string;
    height: string;
    id: string;
}

export interface OpeningHoursModel {
    day: string;
    from: string;
    until: string;
}

export interface ProviderModel {
    name: string;
    tel?: string;
    url?: string;
    show: boolean;
}

export interface ManufacturerModel {
    name: string;
}

export interface DataSource {
    providers: Array<ProviderModel>;
    stations: Array<StationModel>;
    charger_types: Array<ChargerModel>;
    payment_methods: Array<number>;
    output_range: OutputRange;
    manufacturers: Array<ManufacturerModel>;
}

export interface MarkerModel extends google.maps.Marker {
    station?: StationModel;
}

export enum StationStatus {
    draft = 'draft',
    inDev = 'inDev',
    open = 'open',
    tempClosed = 'tempClosed',
    closed = 'closed'
}

export interface OutputRange {
    min: number;
    max: number;
}