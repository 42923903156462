import { Vue, Component } from "vue-property-decorator";
import App from "../App";
import { StationModel, StationChargerModel } from "../Station";
import { FlickityOptions } from "../../Flickity/FlickityOptions";

@Component({
    components: {
        'flickity': () => import(/* webpackChunkName: "Flickity" */ "../../Flickity/Flickity.vue")
    },
    data() {
        return {
            station: undefined
        };
    }
})
export default class StationDetail extends Vue {
    protected station: StationModel | undefined = undefined;
    private flickityOptions: FlickityOptions = {
        pageDots: false,
        lazyLoad: 1
    };

    private mounted() {
        this.setStation();
    }

    private updated() {
        this.setStation();
    }

    private setStation() {
        const app = <App>this.$parent;
        this.station = app.getStationById(
            parseInt(this.$route.params.id, 10)
        );

        if (this.station) {
            const marker = app.findMarkerByLocation(
                parseFloat(this.station.location.lat),
                parseFloat(this.station.location.long)
            );
            if (marker) {
                app.higlightMarker(marker);
            }

            // init Flickity gallery
            if (this.$refs.flickity) {
                (<any>this.$refs.flickity).init();
            }

            // set document meta
            document.title = this.documentTitle;
            document.querySelector('head > meta[name="description"]')!.setAttribute("content", this.station.note ? this.station.note : this.station.location!.address!);
        }
    }

    protected get documentTitle(): string {
        return  `Mapa nabíjecích stanic: ${this.station?.name} | fDrive.cz`;
    }

    private get sortedChargers(): StationChargerModel[] {
        if (this.station && Array.isArray(this.station.chargers)) {
            return this.station.chargers.sort((a, b) => parseInt(b.count) - parseInt(a.count));
        }

        return [];
    }

    private get flickityCellOptions() {
        return {
            cells: this.station!.pics!.map(pic => {
                return {
                    url: `https://cdn.24net.cz/5/obrazek/${pic.media_url}-${pic.id}`,
                    width: pic.width,
                    title: 'Fotografie stanice'
                };
            }),
            breakpoints: [320, 520, 852, 1280, 1920, 2560],
            sizes: "(min-width: 1040px) 520px, (min-width: 768px) 50vw, 100vw'",
            sizesFullscreen: "100vw",
            defaultSize: "520w"
        }
    }

    private get ldJson() {
        if (this.station) {
            return JSON.stringify({
                "@context": "http://schema.org",
                "@type": "GasStation",
                "address": this.station.location.address,
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": this.station.location.lat,
                    "longitude": this.station.location.long
                },
                "description": this.station.note,
                "name": this.station.name,
                "openingHoursSpecification": this.station.opening_hours,
                "paymentAccepted": this.station.payment_methods,
                "maximumAttendeeCapacity": this.station.parking_slots,
                "image": ((this.station.pics && this.station.pics[0]) ? `https://cdn.24net.cz/5/obrazek/${this.station.pics[0].media_url}-${this.station.pics[0].id}/520w` : ''),
                "url": `https://${location.hostname}/mapa-nabijecich-stanic/${this.station.url}-${this.station.id}`
            });
        } else {
            return '';
        }
    }

    private sendReportMail() {
        window.open(
            "mailto:mapa-stanic@24net.cz?subject=Hlášení o chybě u nabíjecí stanice&body=Zachovejte prosím pro referenci: " +
            window.location +
            "%0D%0A%0D%0ANíže prosím popište, kterou informaci bychom měli upřesnit. Děkujeme!%0D%0A-------------------------------------------------%0D%0A%0D%0A%0D%0A"
        );
    }

    private nl2br(value: string) {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }
        return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    }

    private removeZeros(number: string) {
        return parseFloat(number).toString().replace(".", ",");
    }

    private formatOutputPowers(charger: StationChargerModel) {
        if (charger.max_current && charger.max_output) {
            return `(${this.removeZeros(charger.max_output)} kW/${this.removeZeros(charger.max_current)} A)`;
        } else if(charger.max_output) {
            return `(${this.removeZeros(charger.max_output)} kW)`;
        } else if(charger.max_current) {
            return `(${this.removeZeros(charger.max_current)} A)`;
        }
        return '';
    }
}