import {
  StationModel,
  ProviderModel,
  DataSource,
  Station,
  MarkerModel,
  StationStatus,
  OutputRange,
  ChargerModel,
  ManufacturerModel,
} from "./Station";
import axios from "axios";
import { Filter } from "./Filter";
import { RawLocation } from "vue-router/types/router";
import Vue from "vue";

export abstract class AppBase extends Vue {
  /** Goole Maps API key */
  private apiKey = "AIzaSyDviHBqOWqId_Ila47cpyNyIByMqDgoNw8";
  /** Google Maps object */
  private gMap: google.maps.Map | undefined = undefined;
  /** Current location (geo) */
  private currentLocation: google.maps.LatLngLiteral | undefined;
  /** Marker of current location (blue dot) */
  private currentLocationMarker: google.maps.Marker | null = null;
  /** List of all Stations */
  private stations: Array<StationModel> = [];
  /** List of all Markers */
  private markers: Array<MarkerModel> = [];
  /** List of all available Providers */
  public providers: Array<ProviderModel> = [];
  /** List of all available Charger types */
  public charger_types: Array<ChargerModel> = [];
  /** List of all available Payment Methods */
  public payment_methods: Array<number> = [];
  /** List of all available station Manufacturers */
  public manufacturers: Array<ManufacturerModel> = [];
  /** Power output range to offer in filter */
  public output_range: OutputRange = { min: 0, max: 350 };
  /** Is JSON of sttaions already loaded? */
  private stationsLoaded = false;
  /** Is Google Map already attached to DOM? */
  private gMapLoaded = false;
  /** Active filter values object */
  private filter = new Filter({
    type: "",
    provider: "",
    charger_type: "",
    payment_method: "",
    min_output: 0,
  });
  /** Marker for a place from Goole Maps Places search */
  private searchMarker: google.maps.Marker | undefined = undefined;

  /** URL to load JSON of stations */
  protected abstract sourceUrl: string;
  /** Customize colors of station pins */
  protected pinColors = {
    fast: "orange",
    regular: "green",
    selected: "yellow",
    wip: "grey"
  };
  protected allowGeoAccessMessage = "Povolte prosím přístup k poloze ve Vašem prohlížeči pro získání plné funkcionality.";

  protected mounted() {
    // load Google Maps API script and register callback to this.initGmap()
    new Promise((resolve, reject) => {
      (window as any).GoogleMapsInit = resolve;
      const GMap = document.createElement("script");
      GMap.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=GoogleMapsInit&libraries=places&loading=async`
      );
      GMap.async = true;
      document.head.appendChild(GMap);
    }).then(() => {
      this.initGmap();
    });
  }

  /**
   * Center map on current location
   */
  protected centerCurrentLocation(event: MouseEvent) {
    event.preventDefault();
    if (this.gMap && this.currentLocationMarker) {
      if (navigator.geolocation) {
        if (this.currentLocation) {
          this.currentLocationMarker.setPosition(this.currentLocation);
          this.gMap.panTo(this.currentLocation);
          this.gMap.setZoom(15);
        } else {
          this.initGeolocation(() => {
            if (this.currentLocation) {
              this.currentLocationMarker!.setPosition(this.currentLocation);
              this.gMap!.panTo(this.currentLocation);
              this.gMap!.setZoom(15);
            }
          });
        }
      } else {
        (window as any).App.message.show(
          "Váš prohlížeč bohužel nepodporuje geolokaci.",
          "fail"
        );
      }
    } else {
      (window as any).App.message.show(
        "Počkejte prosím, než se inicializuje vaše poloha.",
        "fail"
      );
    }
  }

  /**
   * Initialize Google Map component and attach to selected element
   */
  private initGmap() {
    // init Google Map
    this.gMap = new google.maps.Map(document.getElementById("embed-map")!, {
      zoom: 8,
      center: { lat: 49.784389, lng: 15.603616 },
      fullscreenControl: false,
    });

    // init geolocation
    this.initGeolocation();

    this.gMapLoaded = true;

    // load JSON of all Stations
    this.loadStations().then(() => {
      this.stationsLoaded = true;

      if (this.$route.name === "home" && navigator.geolocation) {
        // get geolocation and zoom in
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // location returned
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            if (this.gMap) {
              this.gMap.setCenter(pos);
              this.gMap.setZoom(12);
            }
          },
          this.fitCountryInView, // location not returned, show whole country
          {
            enableHighAccuracy: false,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else if (this.$route.name === "home") {
        // fit whole country in map view in geo not allowed
        this.fitCountryInView();
      } else if (this.$route.name === "stationDetail") {
        // center map to marker if page loaded on detail
        const station = this.getStationById(
          parseInt(this.$route.params.id, 10)
        );
        if (this.gMap && station) {
          this.gMap.setZoom(15);
          this.gMap.setCenter({
            lat: parseFloat(station.location.lat),
            lng: parseFloat(station.location.long),
          });
        }
      }
    });
  }

  /**
   * Initilalize Geolocation in browser and try to get curretn location
   * 
   * @param sucessFnc Function to call, if curretn location if acquired
   */
  private initGeolocation(sucessFnc: (() => unknown) | undefined = undefined) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // add current location marker (blue dot)
          this.currentLocationMarker = new google.maps.Marker({
            position: this.currentLocation,
            map: this.gMap,
            icon: {
              url:
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAAAF96VFh0UmF3IHByb2ZpbGUgdHlwZSBBUFAxAABo3uNKT81LLcpMVigoyk/LzEnlUgADYxMuE0sTS6NEAwMDCwMIMDQwMDYEkkZAtjlUKNEABZgamFmaGZsZmgMxiM8FAEi2FMnxHlGkAAADqElEQVRo3t1aTWgTQRQOiuDPQfHs38GDogc1BwVtQxM9xIMexIN4EWw9iAehuQdq0zb+IYhglFovClXQU+uhIuqh3hQll3iwpyjG38Zkt5uffc4XnHaSbpLZ3dnEZOBB2H3z3jeZN+9vx+fzYPgTtCoQpdVHrtA6EH7jme+/HFFawQBu6BnWNwdGjB2BWH5P32jeb0V4B54KL5uDuW3D7Y/S2uCwvrUR4GaEuZABWS0FHhhd2O4UdN3FMJneLoRtN7Y+GMvvUw2eE2RDh3LTOnCd1vQN5XZ5BXwZMV3QqQT84TFa3zuU39sy8P8IOqHb3T8fpY1emoyMSQGDI/Bwc+0ELy6i4nLtepp2mE0jc5L3UAhMsdxut0rPJfRDN2eMY1enF8Inbmj7XbtZhunkI1rZFD/cmFMlr1PFi1/nzSdGkT5RzcAzvAOPU/kVF9s0ujqw+9mP5QgDmCbJAV7McXIeGpqS3Qg7OVs4lTfMD1Yg9QLR518mZbImFcvWC8FcyLAbsev++3YETb0tn2XAvouAvjGwd14YdCahUTCWW6QQIzzDO/CIAzKm3pf77ei23AUkVbICHr8pnDZNynMQJfYPT7wyKBzPVQG3IvCAtyTsCmRBprQpMawWnkc+q2Rbn+TK/+gmRR7qTYHXEuZkdVM0p6SdLLYqX0LItnFgBxe3v0R04b5mGzwnzIUMPiBbFkdVmhGIa5tkJ4reZvyl4Rg8p3tMBh+FEqUduVRUSTKTnieL58UDG76cc70AyMgIBxs6pMyIYV5agKT9f/ltTnJFOIhuwXOCLD6gQ/oc8AJcdtuYb09xRQN3NWULgCwhfqSk3SkaBZViRTK3EYNUSBF4Hic0Y8mM+if0HhlMlaIHbQ8Z5lszxnGuIP2zrAw8J8jkA7pkMAG79AKuPTOOcgWZeVP5AsSDjAxWegGyJoSUWAj/FBpRa0JiviSbfldMqOMPcce7UVeBLK4gkMVVBLI2phLjKlIJm8lcxMNkLuIomXOTTmc1kwYf2E+nMQdzlaTTKgoaZJWyBQ141RY0DkrK6XflAQbih1geZnhJeXu5WeEZ3mVqSkrIgCzXJaXqoh65TUuLerdtFXgQ2bYKeD1pq6hobLE86SlztXMWvaA5vPO0sYWB9p2K1iJS4ra0Fju/udsN7fWu+MDRFZ+YuuIjX1d8Zu2OD92WC9G3ub1qABktBV7vssfBMX1L7yVjZ7PLHuABb9svezS7boNDyK/b4LdX123+Au+jOmNxrkG0AAAAAElFTkSuQmCC",
              scaledSize: new google.maps.Size(24, 24),
              size: new google.maps.Size(48, 48),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(12, 12),
            },
            zIndex: 1000000,
          });

          // run success function
          if (sucessFnc) {
            sucessFnc();
          }

          // watch for location change
          navigator.geolocation.watchPosition(
            (position) => {
              this.currentLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.currentLocationMarker!.setPosition(this.currentLocation);
            },
            function(reason) {
              // watch for location failed
              console.log("Couldn't retrieve current location.'", reason);
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
          );
        },
        () => {
          // display geolocation permissions error
          if ((window as any).App?.message) {
            (window as any).App.message.show(
              this.allowGeoAccessMessage,
              "fail"
            );
          } else {
            alert(
              this.allowGeoAccessMessage
            );
          }
        },
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  }

  /**
   * Load list of all Stations form server
   */
  private async loadStations() {
    try {
      const results = await axios.get<DataSource>(this.sourceUrl);
      this.stations = [];
      this.markers = [];
      this.providers = results.data.providers;
      this.charger_types = results.data.charger_types;
      this.payment_methods = results.data.payment_methods;
      this.manufacturers = results.data.manufacturers;

      Object.entries(results.data.stations).forEach(([id, _station]) => {
        const station = new Station(parseInt(id, 10), _station);
        // create a Marker on the Map for each loaded Station
        const marker: MarkerModel = new google.maps.Marker(<any>{
          position: { lat: station.location.lat, lng: station.location.long },
          map: this.gMap,
          title: station.name,
          station: station,
          icon: this.formatMarkerIcon(station),
        });
        marker.addListener("click", (_marker: google.maps.MapMouseEvent) => {
          this.showStation(_marker);
        });
        // load position from Marker (Google sometimes reformats the location values)
        if (marker.getPosition()) {
          station.location.lat = marker
            .getPosition()!
            .lat()
            .toString();
          station.location.long = marker
            .getPosition()!
            .lng()
            .toString();
        }
        this.stations.push(station);
        this.markers.push(marker);
      });
    } catch (reason) {
      (window as any).App.message.show(
        "Nepodařilo se načíst mapové podklady. Zkuste stránku znovu načíst.",
        "fail"
      );
      console.error(reason);
    }
  }

  /**
   * Fit whole Czech Republic in map view
   */
  protected fitCountryInView() {
    if (this.gMap) {
      this.gMap.fitBounds({
        north: 51.041956,
        south: 48.564129,
        east: 18.858349,
        west: 12.10725,
      });
    }
  }

  /**
   * Show Station detail based on clicked Map Marker
   * 
   * @param marker Event of clicked Marker
   */
  private showStation(marker: google.maps.MapMouseEvent) {
    this.showStationFromLatLong(marker.latLng.lat(), marker.latLng.lng());
  }

  /**
   * Show Station detail based on location (lat, long)
   * 
   * @param lat 
   * @param long 
   */
  private showStationFromLatLong(lat: number, long: number) {
    const marker = this.findMarkerByLocation(lat, long);
    if (marker) {
      this.$router.push(`/${marker.station!.url}-${marker.station!.id}`);
    } else {
      (window as any).App.message.show(
        "Tuto stanici se nepodařilo nalézt.",
        "fail"
      );
    }
  }

  /**
   * Find Map Marker based on provided location (lat, long)
   * 
   * @param lat Latitude
   * @param long Longtitude
   */
  public findMarkerByLocation(lat: number, long: number) {
    let marker = this.markers.find((marker) => {
      const position = marker.getPosition();
      if (position) {
        return position.lat() === lat && position.lng() === long;
      }
      return false;
    });

    if (!marker) {
      marker = this.markers.find((marker) => {
        return (
          marker.station!.location.lat === lat.toString() &&
          marker.station!.location.long === long.toString()
        );
      });
    }

    return marker;
  }

  /**
   * Returns Map Marker Icon object based on selected Station
   * 
   * @param station 
   * @param highlight 
   */
  private formatMarkerIcon(
    station: StationModel | undefined,
    highlight = false
  ): google.maps.Icon {
    let color = this.pinColors.regular;

    if (
      station &&
      (station.status == StationStatus.inDev ||
        station.status == StationStatus.tempClosed)
    ) {
      color = this.pinColors.wip;
    } else if (highlight) {
      color = this.pinColors.selected;
    } else if (station && station.fast_charging) {
      color = this.pinColors.fast;
    }

    return {
      url: `/resources/5/img/charging_station_${color}.svg`,
      scaledSize: new google.maps.Size(30, 42),
    };
  }

  /**
   * Return current URL based on current Filter values
   */
  public getFilterUrl(): RawLocation {
    let url = "";

    switch (this.filter.type) {
      case "rychlonabijeci":
        url = "/t/" + this.filter.type;
        break;
      case "standardni":
        url = "/t/" + this.filter.type;
        break;
    }

    if (this.filter.charger_type !== "") {
      url += "/c/" + this.filter.charger_type;
    }

    if (this.filter.min_output !== 0) {
      url += "/w/" + this.filter.min_output;
    }

    if (this.filter.payment_method !== "") {
      url += "/m/" + this.filter.payment_method;
    }

    if (this.filter.provider !== "") {
      url += "/p/" + this.filter.provider;
    }

    return url ? url : { name: "home" };
  }

  /**
   * Returns Station by ID
   * 
   * @param id Station ID
   */
  public getStationById(id: number): StationModel | undefined {
    if (this.stationsLoaded) {
      return this.stations.find((station) => station.id === id);
    }

    return undefined;
  }

  /**
   * Highlight selected Map Marker and putl all other to default state
   * 
   * @param marker Marker object to highlight
   */
  public higlightMarker(marker: MarkerModel) {
    // reset all markers to default
    this.setDefaultMarkerColors();

    try {
      // check if marker is in map bounds and center map, if not
      setTimeout(() => {
        if (
          this.gMap &&
          !this.gMap.getBounds()!.contains(marker.getPosition()!)
        ) {
          this.gMap.panTo(marker.getPosition()!);
        }
      }, 1000);

      // highlight marker
      marker.setAnimation(google.maps.Animation.DROP);
      marker.setIcon(this.formatMarkerIcon(marker.station, true));
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sets all Markers to default colors
   */
  public setDefaultMarkerColors() {
    try {
      for (const _marker of this.markers) {
        _marker.setIcon(this.formatMarkerIcon(_marker.station));
        _marker.setAnimation(null);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Centers map to provided Google Maps Place and creates a new default Marker for it
   */
  public setMapPosition(place: google.maps.places.PlaceResult) {
    this.clearSearchMarker();

    if (place.geometry) {
      this.gMap!.panTo(place.geometry.location);
      this.gMap!.setZoom(16);

      this.searchMarker = new google.maps.Marker({
        position: place.geometry.location,
        map: this.gMap,
        clickable: false,
        title: place.name,
      });
    }
  }

  /**
   * Clears current Google Maps Place Marker
   */
  public clearSearchMarker() {
    if (this.searchMarker) {
      this.searchMarker.setMap(null);
    }
  }
}
