import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import Partners from "./Partners.vue";
import { Filter } from "../Filter";
import { Route } from "vue-router";
import {
  MarkerModel,
  ProviderModel,
  ChargerModel,
  PaymentMethodModel,
  StationModel,
} from "../Station";
import App from "../App";

@Component({
  components: {
    Partners,
  },
})
export default class Home extends Vue {
  private filtersExpanded = false;
  protected documentTitle = "Mapa nabíjecích stanic | fDrive.cz";
  protected documentDescr =
    "Naplánujete si svojí cestu elektromobilem, zjistěte kde se můžete občerstvit a již vícekrát neztrácejte čas hledáním nabíjecí stanice.";
  private positionLabel = "pos-";

  @Prop(Filter) filter!: Filter;
  @Prop(Boolean) stationsLoaded!: boolean;
  @Prop(Boolean) gMapLoaded!: boolean;
  @Prop(Boolean) showPartners!: boolean;

  @Watch("stationsLoaded")
  private onStationsLoadedChanged(value: boolean) {
    this.filterMarkers();
  }

  @Watch("gMapLoaded")
  private onGMapLoadedChanged(value: boolean) {
    this.initGMapAutocomplete();
  }

  private mounted() {
    if (this.$route.name !== "home") {
      this.routeChanged(this.$route);
    }
    (<App>this.$parent).setDefaultMarkerColors();

    if (this.gMapLoaded) {
      this.initGMapAutocomplete();
    }

    // init DFP tag
    try {
      (window as any).googletag.cmd.push(() => {
        (window as any).googletag.display(this.positionLabel + "01");
      });
    } catch (err) {
      //
    }

    // set document meta
    document.title = this.documentTitle;
    document
      .querySelector('head > meta[name="description"]')!
      .setAttribute("content", this.documentDescr);
  }

  private initGMapAutocomplete() {
    const input = <HTMLInputElement | null>(
      document.getElementById("address-input")
    );
    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: { country: ["cz", "sk"] },
      fields: ["name", "geometry"],
    };

    if (input) {
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place) {
          (<App>this.$parent).setMapPosition(autocomplete.getPlace());
        }
      });
    }
  }

  get providersSorted() {
    const providers: Array<ProviderModel> = (<App>this.$parent).$data.providers;

    if (Object.keys(providers).length) {
      return Object.entries(providers)
        .filter(([id, provider]) =>
            provider.show && ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
            station.providers?.find((_provider) => _provider.toString() === id)
          )
        )
        .sort(([a_id, a_provider], [b_id, b_provider]) => {
          const textA = a_provider.name.toUpperCase();
          const textB = b_provider.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    } else {
      return [];
    }
  }

  get chargerTypesSorted() {
    const chargers: Array<ChargerModel> = (<App>this.$parent).$data.charger_types;

    if (Object.keys(chargers).length) {
      // filter out not used properties
      return Object.entries(chargers)
        .filter(([id, charger]) =>
            charger.show && ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
            station.chargers?.find((_charger) => _charger.id === id)
          )
        )
        .sort(([a_id, a_charger], [b_id, b_charger]) =>
          a_charger.order < b_charger.order
            ? -1
            : a_charger.order > b_charger.order
            ? 1
            : 0
        );
    } else {
      return [];
    }
  }

  get paymentMethodsSorted() {
    const methods: Array<PaymentMethodModel> =
    (<App>this.$parent).$data.payment_methods;

    if (Object.keys(methods).length) {
      return Object.entries(methods)
        .filter(([id, method]) =>
          ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
            station.payment_methods?.find(
              (_method) => _method.toString() === id
            )
          )
        )
        .sort(([a_id, a_method], [b_id, b_method]) =>
          a_method.order < b_method.order
            ? -1
            : a_method.order > b_method.order
            ? 1
            : 0
        );
    } else {
      return [];
    }
  }

  private filterTypeClicked(value: string, pushState = true) {
    if (value === this.filter.type) {
      this.filter.type = "";
    } else {
      this.filter.type = value;
    }
    this.updateFilter();
  }

  @Watch("filter.charger_type")
  private onFilterChargerTypeChanged(value: string) {
    this.updateFilter();
  }

  @Watch("filter.min_output")
  private onFilterMinOutputChanged(value: string) {
    this.updateFilter();
  }

  @Watch("filter.payment_method")
  private onFilterPaymentMethodChanged(value: string) {
    this.updateFilter();
  }

  @Watch("filter.provider")
  private onFilterProviderChanged(value: string) {
    this.updateFilter();
  }

  @Watch("$route")
  private routeChanged(route: Route) {
    if (route.params.type) {
      this.filter.type = route.params.type;
    } else {
      this.filter.type = "";
    }

    if (route.params.charger_type) {
      this.filter.charger_type = route.params.charger_type;
    } else {
      this.filter.charger_type = "";
    }

    if (route.params.min_output) {
      this.filter.min_output = parseInt(route.params.min_output, 10);
    } else {
      this.filter.min_output = 0;
    }

    if (route.params.payment_method) {
      this.filter.payment_method = route.params.payment_method;
    } else {
      this.filter.payment_method = "";
    }

    if (route.params.provider) {
      this.filter.provider = route.params.provider;
    } else {
      this.filter.provider = "";
    }

    this.filterMarkers();
  }

  private updateFilter() {
    this.$router.push((<App>this.$parent).getFilterUrl()).catch((reason) => {
      if (reason.name !== "NavigationDuplicated") {
        throw reason;
      }
    });
  }

  private filterMarkers() {
    const markers: Array<MarkerModel> = (<App>this.$parent).$data.markers;
    if (this.stationsLoaded && markers && markers.length) {
      markers.forEach((marker) => {
        marker.setVisible(
          this.filterMarkerType(marker) &&
            this.filterMarkerProvider(marker) &&
            this.filterMarkerChargerType(marker) &&
            this.filterMarkerPaymentMethod(marker) &&
            this.filterMarkerMinOutput(marker)
        );
      });
    }
  }

  private filterMarkerType(marker: MarkerModel) {
    return (
      this.filter.type === "" ||
      (this.filter.type === "rychlonabijeci" &&
        marker.station!.fast_charging === true) ||
      (this.filter.type === "standardni" &&
        marker.station!.fast_charging === false)
    );
  }

  private filterMarkerProvider(marker: MarkerModel) {
    return (
      this.filter.provider === "" ||
      marker.station!.providers.find((id) => {
        return id.toString() === this.filter.provider;
      }) !== undefined
    );
  }

  private filterMarkerChargerType(marker: MarkerModel) {
    return (
      this.filter.charger_type === "" ||
      (Array.isArray(marker.station!.chargers) &&
        marker.station!.chargers.find(
          (charger) => charger.id === this.filter.charger_type
        ) !== undefined)
    );
  }

  private filterMarkerPaymentMethod(marker: MarkerModel) {
    return (
      this.filter.payment_method === "" ||
      (Array.isArray(marker.station!.payment_methods) &&
        marker.station!.payment_methods.find(
          (id) => id.toString() === this.filter.payment_method
        ) !== undefined)
    );
  }

  private filterMarkerMinOutput(marker: MarkerModel) {
    marker.station!.chargers;

    return (
      this.filter.min_output === 0 ||
      (Array.isArray(marker.station!.chargers) &&
        marker.station!.chargers.find(
          (charger) =>
            parseInt(charger.max_output, 10) >= this.filter.min_output
        ) !== undefined)
    );
  }

  public get filtersSum(): string {
    const app = <App>this.$parent;
    const filters: string[] = [];

    if (this.filter.charger_type)
      filters.push(app.charger_types[this.filter.charger_type].name);
    if (this.filter.min_output) filters.push(`${this.filter.min_output}+ kW`);
    if (this.filter.payment_method)
      filters.push(app.payment_methods[this.filter.payment_method].name);
    if (this.filter.provider)
      filters.push(app.providers[this.filter.provider].name);

    if (filters.length > 2) {
      return `(${filters[0]} a ${filters.length - 1} další …)`;
    } else if (filters.length > 1) {
      return `(${filters[0]}, ${filters[1]})`;
    } else if (filters.length) {
      return `(${filters[0]})`;
    }

    return "";
  }
}
