export class Filter implements FilterModel {
    public type: string;
    public provider: string;
    public charger_type: string;
    public payment_method: string;
    public min_output: number;

    constructor({ type = '', provider = '', charger_type = '', payment_method: paymnet_method = '', min_output = 0 }: FilterModel) {
        this.type = type;
        this.provider = provider;
        this.charger_type = charger_type;
        this.payment_method = paymnet_method;
        this.min_output = min_output;
    }

    /**
     * Number of used filters
     */
    public get length(): number {
        let length = 0;

        if (this.type) length++;
        if (this.provider) length++;
        if (this.charger_type) length++;
        if (this.payment_method) length++;
        if (this.min_output) length++;

        return length;
    }

}

export interface FilterModel {
    type: string;
    provider: string;
    charger_type: string;
    payment_method: string;
    min_output: number;
}